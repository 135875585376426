import React, { useState, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import BigLink from "../components/BigLink"

import "../css/effects.css"

const IndexPage = () => {
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined" && window.innerWidth < 768) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }, [])

  return (
    <StaticQuery
      query={graphql`
        query pageImgs {
          phantom4k: file(
            relativePath: { eq: "phantom/Flex4K-02-RightMain-bg.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 398
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          alexa: file(
            relativePath: {
              eq: "alexa/alexa-mini-perspektiven-nebeneinander-bg.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 398
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          alexa35: file(
            relativePath: { eq: "alexa-35/alexa-35-stage-platzhalter-data.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 398
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          innovision: file(
            relativePath: { eq: "innovision/Innovision-prob-2.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 398
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          innovisionHR: file(
            relativePath: { eq: "icones-em-preto/Innovision_HR_black_2.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 398
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          cinemagic: file(
            relativePath: { eq: "icones-em-preto/cinemagic-2-Icon_Black.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 398
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          laowa12: file(
            relativePath: { eq: "icones-em-preto/Laowa_12_Icon_Black.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 398
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          laowa24: file(
            relativePath: { eq: "icones-em-preto/Laowa_24_black.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 398
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          laowa24T8: file(
            relativePath: { eq: "icones-em-preto/Laowa_24_T8_Black_1.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 398
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          banner1: file(relativePath: { eq: "image-bank/Banner_5.jpg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [640, 768, 1024, 1280, 1536]
              )
            }
          }
          banner1768: file(
            relativePath: { eq: "image-bank/Banner_5_mobile.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 768
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [640, 768, 1024, 1280, 1536]
              )
            }
          }
          banner2: file(relativePath: { eq: "image-bank/Banner_4.jpg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          banner2768: file(
            relativePath: { eq: "image-bank/Banner_4_mobile.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 768
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [640, 768, 1024, 1280, 1536]
              )
            }
          }
        }
      `}
      render={data => (
        <>
          <Metatags
            title="Speedcam Brasil"
            description="Speed Cam - Rental House"
            url="https://speedcambrasil.com.br"
            image="https://speedcambrasil.com.br/logo.png"
            keywords="speed cam, rental, Phanton, Alexa Mini, Alexa 35, Innovision HD, Innovision HR, Cinemagic Revolution, Laowa 12, Laowa 24"
            language="pt-br"
          />
          <NavBar />

          <div className="mx-auto max-w-12xl">
            <div className="relative">
              {mobile ? (
                <GatsbyImage
                  image={getImage(data.banner1768)}
                  backgroundColor="transparent"
                  placeholder="blurred"
                  alt="high speed"
                />
              ) : (
                <GatsbyImage
                  image={getImage(data.banner1)}
                  backgroundColor="transparent"
                  placeholder="blurred"
                  alt="high speed"
                />
              )}

              <h1 className="absolute left-2 top-4 md:left-20 md:inset-y-1/4 text-sc-yellow">
                <i>
                  <p className="text-2xl md:text-6xl font-semibold">
                    HIGH SPEED
                  </p>
                  <p className="text-base md:text-2xl font-semibold">
                    &nbsp;
                    <br />
                    UMA EQUIPE ESPECIALIZADA PREPARADA PARA
                  </p>
                  <p className="text-base md:text-2xl font-semibold">
                    TRATAR CADA PROJETO DE FORMA PERSONALIZADA
                  </p>
                </i>
              </h1>
            </div>
          </div>
          <div className="h-10" />
          <div>
            <div className="mx-auto max-w-7xl">
              <div className="text-center text-sc-yellow">
                <i>
                  <p className="text-4xl font-semibold">CÂMERAS</p>
                </i>
              </div>
              <div className="h-2" />
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-2">
                <div className="text-center">
                  <BigLink
                    link="phantom-flex-4k"
                    image={data.phantom4k}
                    title="Phantom Flex 4k"
                  />
                </div>
                <div className="text-center">
                  <BigLink
                    link="alexa-mini"
                    image={data.alexa}
                    title="Arri Alexa mini"
                  />
                </div>
                <div className="text-center">
                  <BigLink
                    link="alexa-35"
                    image={data.alexa35}
                    title="Arri Alexa 35"
                  />
                </div>
              </div>
            </div>
            <div className="h-10" />
            <div className="mx-auto max-w-7xl">
              <div className="text-center text-sc-yellow">
                <i>
                  <p className="text-4xl font-semibold">LENTES</p>
                </i>
              </div>
              <div className="h-2" />
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-2">
                <div className="text-center">
                  <BigLink
                    link="innovision-hd"
                    image={data.innovision}
                    title="Innovision HD"
                  />
                </div>
                <div className="text-center">
                  <BigLink
                    link="innovision-hr"
                    image={data.innovisionHR}
                    title="Innovision HR"
                  />
                </div>
                <div className="text-center">
                  <BigLink
                    link="cinemagic-revolution"
                    image={data.cinemagic}
                    title="Cinemagic Revolution"
                  />
                </div>
                {/* </div>
            </div>
            <div className="mx-auto max-w-4xl">
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-2"> */}
                <div className="text-center">
                  <BigLink
                    link="laowa-12"
                    image={data.laowa12}
                    title="Laowa 12"
                  />
                </div>
                <div className="text-center">
                  <BigLink
                    link="laowa-24"
                    image={data.laowa24}
                    title="Laowa 24 T14"
                  />
                </div>
                <div className="text-center">
                  <BigLink
                    link="laowa-24-T8"
                    image={data.laowa24T8}
                    title="Laowa 24 T8"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="h-10" />
          <div className="mx-auto max-w-12xl">
            <div className="relative">
              {mobile ? (
                <GatsbyImage
                  image={getImage(data.banner2768)}
                  backgroundColor={false}
                  alt="high spded"
                  className=""
                />
              ) : (
                <GatsbyImage
                  image={getImage(data.banner2)}
                  backgroundColor={false}
                  alt="high spded"
                  className=""
                />
              )}

              <div className="absolute left-2 top-4 md:left-20 md:inset-y-1/4 text-sc-yellow">
                <i>
                  <p className="text-2xl md:text-6xl font-semibold">
                    CÂMERA, AÇÃO!
                  </p>
                  <p className="text-base md:text-2xl font-semibold">
                    &nbsp;
                    <br />
                    OFERECEMOS SERVIÇOS DE ALUGUEL E SUPORTE NO
                  </p>
                  <p className="text-base md:text-2xl font-semibold">
                    DEPARTAMENTO DE CÂMERA PARA CINEMA E PUBLICIDADE
                  </p>
                </i>
              </div>
            </div>
          </div>
          <div className="h-20" />
          <Footer />
        </>
      )}
    />
  )
}

export default IndexPage

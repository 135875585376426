import React from "react"
import { Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import "../css/effects.css"

const BigLink = (props) => {

  const image = getImage(props.image)
  const bgImage = convertToBgImage(image)


  return (
    <>
      <div className="md-promo-cards">
        <span>
          <Link to={props.link}>
            <div className="md-promo-inner">
              <p className="md-promo-title">{props.title}</p>
            </div>
          </Link>
          <div className="md-promo-overlay">
            <div className="md-promo-overlay-image">
              <BackgroundImage
                Tag="section"
                // Spread bgImage into BackgroundImage:
                {...bgImage}
                preserveStackingContext={true}
              />
              <div className="md-promo-overlay-color"></div>
            </div>
          </div>
        </span>
      </div>
    </>
  )
}
export default BigLink


